!function() {
  [
    "mousedown",
    "mousemove",
    "keydown",
    "scroll",
    "touchstart",
    "click",
    "keypress",
    "touchmove"
  ].forEach(eventName => window.addEventListener(eventName, loadJSscripts, false));

  function loadJSscripts(){
    if (!window._customlazyload || window._customlazyload.loaded) return;

    if (window._customlazyload) {
      window._customlazyload.observer.disconnect();
      window._customlazyload.loaded = true;
      window._customlazyload = void 0;
    }
    if (window.yett) window.yett.unblock();

    [...document.querySelectorAll("iframe[data-src], script[data-src], img[data-src]")]
      .forEach(el => {
        const datasrc = el.dataset.src;
        if (datasrc) el.src = datasrc
      });

    [...document.querySelectorAll("link[data-href]")]
      .forEach(el => {
        const datahref = el.dataset.href;
        if (datahref) el.href = datahref
      });

    [...document.querySelectorAll("script[type='text/lazyload']")]
      .forEach(el => {
        const script = document.createElement("script");
        for(let i = 0; i < el.attributes.length; i++){
          var attribute = el.attributes[i];
          script.setAttribute(attribute.name, attribute.value);
        }
        script.type = "text/javascript";
        script.innerHTML = el.innerHTML;
        el.parentNode.insertBefore(script, el);
        el.parentNode.removeChild(el);
      });

    document.dispatchEvent(new CustomEvent("asyncLazyLoad"));
  }
}();
